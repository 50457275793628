import TextModal from "../../Modals/TextModal/TextModal";
import ImageModal from "../../Modals/ImageModal/ImageModal";
import SectionModal from "../../Modals/SectionModal/SectionModal";
import { useSelector } from "react-redux";
import Modal from "../../Native/Modal/Modal";

const ModalRender = () => {
  const loading = useSelector((state: { content: any }) => state.content.isLoading);
  const element = useSelector((state: { content: any }) => state.content.selectedElement);
  const show = useSelector((state: { content: any }) => state.content.show);
  return (
    <>
      {(() => {
          if (loading) { 
            if(element !== null && show ) {
              switch (element.type) {
                case "SECTION":
                  return <SectionModal element={element}  />;
                case "TEXT":
                  return <TextModal element={element} />;
                // Agrega casos para otros tipos si es necesario
                case "IMAGE":
                  return <ImageModal element={element} />;
                default:
                  return <Modal element={element} />; // Otra opción por defecto si no coincide ningún tipo
              }
            }

          }

      })()}
    </>
  );
};

export default ModalRender;
