import { Suspense, lazy, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Section from "../../components/Native/Template/Template";
import { AppDispatch, GetContentsLocalStorage, fetchContent } from "../../store/actions/dataSlice";
import axios from "axios";
import { API_URL } from "../../helpers/api";

export function Site() {
    const dispatch = useDispatch<AppDispatch>();
    let userType = Number(localStorage.getItem('userType') )

    const getIdBoda = async () => {
      const { hostname, pathname } = window.location;



      let eventDomain = ''
        // Verifica si el hostname empieza con "www." y quedar solo con dominio
      eventDomain = hostname.startsWith('www.') ? hostname.slice(4) : hostname;

      if (eventDomain === 'weddingscloud.fun') {

        // weddingscloud.fun/martayrodrigo/
        // weddingscloud.fun/cumpleañoscarlitos/
        // weddingscloud.fun/bautizofanny/

        // Esto elimina las posibles barras inicial y final y extrae el último segmento

        eventDomain = pathname.replace(/^\/|\/$/g, '');
      }

      const res = await axios.get(`${API_URL}getIdBoda`, {
        params: {
            url: eventDomain 
        }
      });

      const idBoda = res.data.idBoda;
      const idSitio = res.data.idSitio;
      localStorage.setItem("idBoda", idBoda);
      localStorage.setItem("idSitio", idSitio); 
      if(idBoda) {
        dispatch(fetchContent())
      }


    }

  // aqui estara el hashmap o la llamada al back para regresar el contenido de el idBoda para que se haga el fetch content y se rendericen los datos
  useEffect(() => {
    getIdBoda()
  }, [])
  const sections = useSelector((state: { content: any }) => state.content);

  return (

    <div>
      {sections?.contents?.body?.sections != undefined &&
        Object.values(sections.contents.body.sections).map((section: any) => {
          const componentType = section.name;
          const meta = {
            ...section.meta,
            idSection: section.id
          };

          let Component = lazy(() => import(`../../components/Templates/Description1/Description1`));
          if (componentType != undefined) {
            Component = lazy(() => import(`../../components/${userType === 1 ? 'Templates_events' : 'Templates'}/${componentType}/${componentType}`));
          }

          return (
            <Suspense key={section.id} fallback={<div>Cargando...</div>}>
              <Section meta={meta} id={section.id} builder={false} section={true}>
                <Component meta={meta} builder={false} />
              </Section>
            </Suspense>
          );
        })}
    </div>
  );
}

