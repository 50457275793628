import { useEffect } from "react";
import { AppDispatch, fetchContent } from "../../../store/actions/dataSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import './home.css';

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/builder')
  },[] )


  return (
    <>
      <div className="admin-home">
        <div className="card">
          <div className="background-image">
            <img src="https://images.unsplash.com/photo-1552516641-551d7a295a0c?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" />
          </div>
          <div className="body">
            <p className="title">Builder</p>
            <p className="description">Crea y personaliza tus diseños únicos de forma rápida y sencilla. ¡Empieza ahora</p>
            <button onClick={() => navigate('/builder') }>Ir al builder <i className="fa-regular fa-arrow-right"></i></button>
          </div>
        </div>
        <div className="card">
          <div className="background-image">
            <img src="https://images.unsplash.com/photo-1612534268757-5ad43935365d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" />
          </div>
          <div className="body">
            <p className="title">Plantillas</p>
            <p className="description">Explora nuestra colección de plantillas diseñadas profesionalmente. ¡Encuentra la perfecta para ti!!</p>
            <button onClick={() => navigate('/templates') }>Ir a plantillas <i className="fa-regular fa-arrow-right"></i></button>
          </div>
        </div>
        <div className="card">
          <div className="background-image">
            <img src="https://images.unsplash.com/photo-1542029962-dabc74540324?q=80&w=1975&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" />
          </div>
          <div className="body">
            <p className="title">Invitaciones</p>
            <p className="description">Envía invitaciones inolvidables a tus seres queridos con nuestras opciones personalizables</p>
            <button onClick={() => navigate('/invitations') }>Ir a invitaciones <i className="fa-regular fa-arrow-right"></i></button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
