import React, { createContext, ReactNode, useContext, useState } from 'react';

// Definir el tipo de contexto, si estás usando TypeScript
type LoadingContextType = {
  loading: boolean;
  showLoading: () => void;
  hideLoading: () => void;
};

// Crear el contexto con un valor por defecto
const LoadingContext = createContext<LoadingContextType | undefined>(undefined);

type LoadingProviderProps = {
    children: ReactNode;
  };

export const LoadingProvider: React.FC<LoadingProviderProps> = ({ children }) => {
    const [loading, setLoading] = useState(false);
  
    const showLoading = () => setLoading(true);
    const hideLoading = () => setLoading(false);
  
    return (
      <LoadingContext.Provider value={{ loading, showLoading, hideLoading }}>
        {children}
      </LoadingContext.Provider>
    );
  };

// Hook personalizado para usar el contexto de loading
export const useLoading = (): LoadingContextType => {
  const context = useContext(LoadingContext);
  
  if (!context) {
    throw new Error('useLoading must be used within a LoadingProvider');
  }
  
  return context;
};
