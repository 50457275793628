import axios from "axios";
import { API_URL } from "./api";
import * as DOMPurify from 'dompurify';
import { useToast } from "../components/Native/Toast/ToastContext";



export const renderTypeComponent = (element: any) => {

    if (element != null) {
      const type = element.type;
      switch (type) {
        case "TEXT":
          return "texto";
        case "IMAGE":
          return "imagen";
        case "SECTION":
          return "sección"
        default:
          return null; // Otra opción por defecto si no coincide ningún tipo
      }
    } else {
      return ''
    }

  };

  export  const handleSave = (state: any, addToast: any) => {
    let data = JSON.stringify(state)
    data = sanitizeInput(data)
    const localStorage = window.localStorage;
    const idBoda = localStorage.getItem('idBoda')

    axios.post(`${API_URL}data?idBoda=`+idBoda, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
    .then(() => {
      addToast('Guardado', 'success');
    })
    .catch(() => {
      addToast('Error al guardar la pagina', 'error');
    });
}

export const sanitizeInput = (input: any) => {
  return DOMPurify.sanitize(input);
}