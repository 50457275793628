// Column.tsx
import React from 'react';
import { useDroppable } from '@dnd-kit/core';

interface ColumnProps {
  id: string;
  children: React.ReactNode;
  className?: string;
}

export function Column({ id, children, className = '' }: ColumnProps) {
  const { isOver, setNodeRef } = useDroppable({
    id: id, // Asegúrate de que el ID sea una cadena
    data: {
      column: id, // Pasar el nombre de la columna en los datos
    },
  });

  return (
    <div ref={setNodeRef} className={`column ${className}`} >
      {children}
    </div>
  );
}
