// Item.tsx
import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface Guest {
  idinvite: string;
  nombre: string;
  apellidos: string;
  count: number;
  // Otros campos si es necesario
}

interface ItemProps {
  id: string;
  index: number;
  column: string;
  guestData: { [key: string]: Guest };
  isOverlay?: boolean;
}

export function Item({ id, index, column, guestData, isOverlay = false }: ItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: id,
    data: {
      column: column,
    },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  const guest = guestData[id];

  if (!guest) {
    return null; // Manejo de casos donde el invitado no está disponible
  }

  // Formatear el nombre según la cantidad de invitados
  const displayName = guest.count > 1
    ? `${guest.nombre} ${guest.apellidos} x ${guest.count}`
    : `${guest.nombre} ${guest.apellidos}`;

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={`item ${isOverlay ? 'dragging' : ''}`}
    >
      {displayName}
    </div>
  );
}
