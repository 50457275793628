
import { useState } from 'react';
import axios from 'axios';
import '../Login/login.css';
import { API_URL } from '../../helpers/api';
import { useToast } from '../../components/Native/Toast/ToastContext';

const Bum = () => {

    const addToast = useToast();

    const [username, setUsername] = useState('');
    const [url, setUrl] = useState('');
    const [template, setTemplate] = useState('1');
    const [userType, setuserType] = useState('0');

    const handleSubmit = (e: any) => {
        e.preventDefault();
        let user = username
        let urlData = url

        const data = JSON.stringify({user: user, url: urlData, template, type: userType });
        axios.post(`${API_URL}newUser`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            addToast(`usuario creado: ${JSON.stringify(response.data) }`, 'success');
        })
        .catch((error) => {
            let errorMessage = 'Error desconocido'; // Mensaje de error predeterminado
            if (error.response) {
                // Si hay una respuesta de error del servidor
                errorMessage = error.response.data.message; // Asigna el mensaje de error proporcionado por el servidor
                addToast(`Error: ${errorMessage }`, 'error');

            } else if (error.request) {
                // Si la solicitud fue realizada pero no se recibió respuesta
                errorMessage = 'No se pudo conectar al servidor';
                addToast(`Error: ${errorMessage }`, 'error');
            } else {
                // Si ocurrió un error durante la configuración de la solicitud
                errorMessage = error.message;
                addToast(`Error: ${errorMessage }`, 'error');
            }
           
        });
    };

    return (
        <div className="body-login">
            <div className="login-form-container">
                <h1 className="login-form-title">Nuevo usuario</h1>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="username"
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Usuario"
                        className="login-form-input"
                    />

                    <label htmlFor="typeSelect">Tipo de usuario:</label>
                    <select id="typeSelect" name="typeSelect" className='login-form-input'  onChange={(e) => setuserType(e.target.value)}>
                        <option value="0">Boda</option>
                        <option value="1">Evento</option>
                    </select>
                    <input
                        type="text"
                        name="url"
                        onChange={(e) => setUrl(e.target.value)}
                        placeholder="Url de sitio:"
                        className="login-form-input"
                    />
                    <label htmlFor="templateSelect">Seleccione un template:</label>
                    <select id="templateSelect" name="templateSelect" className='login-form-input'  onChange={(e) => setTemplate(e.target.value)}>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                    <input
                        type="submit"
                        value="Crear usuario"
                        className="login-form-button"
                    />
                </form>
            </div>
        </div>
    );
};

export default Bum;
