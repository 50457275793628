import { useSelector } from "react-redux";
import { Suspense, lazy, memo } from "react";
import "./sectionsRender.css";
import Template from "../../Native/Template/Template";


const SectionsRender = () => {
  let sections = null; 
    sections = localStorage.getItem('dataBoda') || '';
    sections = JSON.parse(sections).body.sections;

  return (
    <div className="sections-render">
      {sections != undefined &&
        Object.values(sections).map((section: any) => {

          
          const componentType = section.name;
          const meta = {
            ...section.meta,
            idSection: section.id
          };
          let Component = lazy(() => import(`../../Templates/Description1/Description1`)); // why is setting value to description1????
          if (componentType != undefined) {
            Component = lazy(() => import(`../../Templates/${componentType}/${componentType}`));
          }

          // BUILDER FLAG ON SECTION COMPONENT IS SET TO FALSE TO HIDE BUILDER BEHAVIOR.
          return (
            <Suspense key={section.id} fallback={<p>loader en sections render</p>}>
              <Template meta={meta} id={section.id} builder={false} isTemplatePreview={false}> 
                <Component meta={meta} builder={true} isTemplatePreview={false} />
              </Template>
            </Suspense>
          );
        })}
    </div>
  )
};

export default memo(SectionsRender);