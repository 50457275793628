// TextModal.tsx
import React, { useState } from "react";
import Modal from "../../Native/Modal/Modal";
import { ModalProps } from "../../../store/actions/dataSlice";
import './sectionmodal.css'

const SectionModal: React.FC<ModalProps> = ({ element }) => {
  // Asegura que el estado de 'visibility' sea una cadena ('true' o 'false')
  const [visibility, setVisibility] = useState(element.data.toString());

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setVisibility(event.target.value);
  };

  return (
    <Modal data={visibility} element={element}>
      <label className="visibility-select-label" htmlFor="visibility-select">Visibilidad:</label>
      <select id="visibility-select" className="visibility-select-section" value={visibility} onChange={handleSelectChange}>
        <option value="true">Mostrar</option>
        <option value="false">Ocultar</option>
      </select>
    </Modal>
  );
};

export default SectionModal;

