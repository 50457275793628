
import { useDispatch, useSelector } from "react-redux";
import SectionsRender from "../SectionsRender/SectionsRender";
import { useEffect } from "react";
import { AppDispatch, fetchContent } from "../../../store/actions/dataSlice";
//import Text from '../../../../../wedding-assets/src/components/native/Text'
const BuilderRender = () => {
  const dispatch = useDispatch<AppDispatch>();
  const contents = useSelector((state: { content: any }) => state.content.contents);
  useEffect(() => {
    if (Object.keys(contents).length === 0) {
      dispatch(fetchContent())
    }
  }, [])
  return  ( 
  
  <>
     <SectionsRender  />
  </>  )
  

};

export default BuilderRender;
