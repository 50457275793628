import './templates-styles.css';


const TemplatesEvents = ({ onClickTemplate, onPreviewTemplate }: any) => {



    return ( 
        <>
            <div className="template">
                <div className="background-image">
                    <img src="./1.png" alt="" />
                </div>
                <div className="template-data">
                    <p className='template-title'>Summer</p>
                    <button className='template-data-item btn-preview' onClick={() => onPreviewTemplate(1)}>Ver diseño</button>
                    <button className='template-data-item btn-replace' onClick={() => onClickTemplate(1)}>Reemplazar diseño</button>
                </div>
            </div>




        </>      
     );
}
 
export default TemplatesEvents;