import React from 'react';
import './LoadingIndicator.css';
import { useLoading } from './LoadingContext';

const LoadingIndicator: React.FC = () => {
  const { loading } = useLoading();

  return (
    loading ? (
      <div className="loading-indicator">
        <div className="spinner"></div>
        <p>Cargando...</p>
      </div>
    ) : null
  );
};

export default LoadingIndicator;
