import { Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import Section from "../../components/Native/Template/Template";

export function Viewer() {

  const sections = useSelector((state: { content: any }) => state.content);
  return (

    <div className="sections-render">
      {sections?.contents?.body?.sections != undefined &&
        Object.values(sections.contents.body.sections).map((section: any) => {
          const componentType = section.name;
          const meta = {
            ...section.meta,
            idSection: section.id
          };

          let Component = lazy(() => import(`../../components/Templates/Description1/Description1`));
          if (componentType != undefined) {
            Component = lazy(() => import(`../../components/Templates/${componentType}/${componentType}`));
          }

          return (
            <Suspense key={section.id} fallback={<div>Cargando...</div>}>
              <Section meta={meta} id={section.id} builder={false} section={true}>
                <Component meta={meta} builder={false} />
              </Section>
            </Suspense>
          );
        })}
    </div>
  );
}