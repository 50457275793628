import React, { useState } from "react";
import { AddSection, setShowTrue, updateSelectedElement, updateShowSelectedElement } from "../../../store/actions/dataSlice";
import { useDispatch } from "react-redux";



const Element: React.FC<any> = ({
  children,
  meta = {},
  id = "",
  builder = false,
  isTemplatePreview,
  templateId,
  section = false,
}) => {

  const [isHovered, setIsHovered] = useState(false);

  const dispatch = useDispatch()

  let handleDoubleClick = (e: any) => {  e.stopPropagation();};
  let handleHoverEnter = ()  => {};
  let handleHoverLeave = ()  => {};
  let AddSectionBelow = (): any => {};
  let selectedElement: any = null

let sections = null; 

  // BUILDER
  if (builder) {
    if (!isTemplatePreview) {
      sections = localStorage.getItem('dataBoda') || '';
      sections = JSON.parse(sections).body.sections;
    }

    const idElement = id;
    const idSection = meta.idSection;

    const selectSectionById = (sections: any, id: any) => {
      const sectionsArr = Object.values(sections) as any[];
      return sectionsArr.find((section: any) => section.id === id)
    }

    const selectElementById = (sections: any, idSection: any, idElement: any) => {
      const section: any = selectSectionById(sections, idSection).meta;
      return Object.values(section).find(
        (element: any) => element?.id === idElement,
      );
    }

    selectedElement = selectElementById(sections, idSection, idElement);


    handleHoverEnter = () => {
      setIsHovered(true);

      // Realiza acciones adicionales al entrar el mouse, si es necesario
    };

    handleHoverLeave = () => {
      setIsHovered(false);
      // Realiza x x acciones adicionales al salir el mouse, si es necesario
    };

    handleDoubleClick = (event: any) => {
      event.preventDefault();
      if (!section) {
        event.stopPropagation();
      }
      dispatch(updateSelectedElement({ element: selectedElement, idSection }));
      dispatch(updateShowSelectedElement({ show: true }));
      dispatch(setShowTrue());
    };

    AddSectionBelow = (): any  => {
      dispatch(AddSection({ idSection }))
      };

    // END BUILDER
  }

  return (
    <>

        <div className={isHovered ? "hovered" : "noHovered"}       
            onMouseEnter={builder ? handleHoverEnter : undefined}
            onMouseLeave={builder ? handleHoverLeave : undefined}
            onDoubleClick={builder ? handleDoubleClick : undefined}>
                {children}
        </div>
    </>
  );
};
//       { !section ? <div className="add-section" onClick={  AddSectionBelow }>Añadir Sección</div>  : ""}
export default Element;
