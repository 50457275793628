import { useState } from 'react';

import './colorPicker.css'
const ColorPicker = ({onChange, value}: any) => {
  const [color, setColor] = useState(value);
  const [active, setActive] = useState(false);
  const [oldColor, setOldColor] = useState(value);

  const handleChange = (e: any) => {
    setOldColor(color);
    setColor(e.target.value);
    setActive(!active);
    onChange(e)
  };

  return (
    <div>
      
      <label className="color-selector">
        <span className="circle" style={{ background: value }} />
        <span>{color}</span>
        <input
          type="color"
          value={value}
          onChange={handleChange}
          className="hidden"
        />
      </label>
    </div>
  );
};

export default ColorPicker;
