import './Template5.css';

const Template5 = () => {
  return (
    <div className="template5-invite">
      <header className="template5-header">
        <p className="template5-couple-name">Ana & Luis</p>
        <p className="template5-date">12 de Junio de 2025</p>
      </header>

      <section className="template5-hero">
        <img
          src="https://images.unsplash.com/photo-1523779105320-313c9e40d14c"
          alt="Ana y Luis"
          className="template5-hero-image"
        />
        <div className="template5-overlay">
          <p className="template5-location">Ciudad de México</p>
        </div>
      </section>

      <section className="template5-story">
        <p className="template5-section-title">Nuestra Historia</p>
        <p className="template5-story-text">
          Desde el primer momento en que nos conocimos, supimos que nuestro destino era estar juntos. Hemos
          compartido risas, aventuras y mucho amor. Ahora queremos compartir nuestro día especial contigo.
        </p>
      </section>

      <section className="template5-details">
        <div className="template5-event">
          <p className="template5-event-title">Ceremonia Religiosa</p>
          <p>Iglesia de Guadalupe</p>
          <p>4:00 PM</p>
        </div>
        <div className="template5-event">
          <p className="template5-event-title">Recepción</p>
          <p>Jardín Las Flores</p>
          <p>6:00 PM</p>
        </div>
      </section>

      <section className="template5-gallery">
        <p className="template5-section-title">Galería</p>
        <div className="template5-gallery-images">
          <img src="https://images.unsplash.com/photo-1517486808906-6ca8b3f04846" alt="Galería 1" />
          <img src="https://images.unsplash.com/photo-1520545492265-3b50fcd552e3" alt="Galería 2" />
          <img src="https://images.unsplash.com/photo-1528569937393-e3362cec87c3" alt="Galería 3" />
        </div>
      </section>

      <section className="template5-dresscode">
        <p className="template5-section-title">Código de Vestimenta</p>
        <p>Formal - Colores Pastel</p>
      </section>

      <section className="template5-gifts">
        <p className="template5-section-title">Mesa de Regalos</p>
        <p>
          Agradecemos tu presencia, pero si deseas hacernos un obsequio, aquí están nuestras opciones:
        </p>
        <ul className="template5-gifts-list">
          <li>Liverpool - Número de lista: 123456</li>
          <li>Amazon - Boda Ana y Luis</li>
        </ul>
      </section>

      <footer className="template5-footer">
        <p>¡Esperamos contar contigo en nuestro día especial!</p>
        <p>Confirma tu asistencia al 555-6789-012</p>
      </footer>
    </div>
  );
};

export default Template5;
