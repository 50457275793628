import "./builder.css";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from "./Pages/Builder/Home/Home";
import { Viewer } from "./Pages/Viewer/Viewer";
import Login from "./Pages/Login/login";
import PrivateRoutes from "./helpers/PrivateRoutes";
import Builder from "./Pages/Builder/Builder";
import Templates from "./Pages/Templates/Templates";
import Invitations from "./Pages/Invitations/Invitations";
import { Site } from "./Pages/Site/Site";
import Bum from "./Pages/Bum/Bum";
import { TemplateRenderer } from "./Pages/TemplateRenderer/TemplateRenderer";
import Planner from "./Pages/Planner/Planner";
import { Tables } from "./Pages/Tables/Tables";
import Template5 from "./components/Templates/Template5/Template5";
import { IS_LOCAL } from "./helpers/api";
import Cbum from "./Pages/Cbum/cbum";


function App() {
  const localStorage = window.localStorage;
  const { hostname } = window.location;
  const parts = hostname.split('.');
  
  // Si es un subdominio, toma los últimos dos segmentos como el dominio
  const domain = parts.length > 2 
    ? parts.slice(-2).join('.')  // Para dominios como 'example.com' o 'co.uk'
    : hostname;
  
  
  // Detectar el subdominio si existe
  const subdomain = parts.length > 2 ? parts[0] : '';
  
  // Verificar si es superadmin (puede basarse en el subdominio o dominio)
  let isSuperAdmin = domain === 'weddingscloud.fun';

  let isAdmin: boolean;
  const isLocal = (IS_LOCAL as any).toLowerCase() === "true";
 
  if (isLocal) {
    isAdmin = true;
    isSuperAdmin = true;
  } else {
    isAdmin = subdomain === 'admin';
  }

  return (
    <Router>
      <Routes>
        {/* Rutas para admin.site.com */}
        { isAdmin ? (
          <>
            <Route element={<PrivateRoutes />}>
              <Route path="/home" element={<Builder />} />
              <Route path="/builder" element={<Builder />} />
              <Route path="/viewer" element={<Viewer />} />
              <Route path="/templates" element={<Templates />} />
              <Route path="/invitations" element={<Invitations />} />
              <Route path="/planner" element={<Planner />} />
              <Route path="/tables" element={<Tables />} />
              <Route path="/template" element={<Template5 />} />

              <Route path="*" element={<Home />} />
            </Route>        
            {/* Rutas públicas */}
            <Route path="/login" element={<Login />} />
            <Route path="/templateRenderer" element={<TemplateRenderer />} />
            <Route
                path="/bum"
                element={isSuperAdmin ? <Bum /> : <Navigate to="/home" replace />}
              />
              <Route
                path="/cbum"
                element={isSuperAdmin ? <Cbum /> : <Navigate to="/home" replace />}
              />
          </>
        ) : (
          // Rutas para site.com
          <>
            <Route path="/site" element={<Site />} />
            {/* Redirect to Site for any other route */}
            <Route path="*" element={<Site />} />
          </>
        )}

      </Routes>
    </Router>
  );
}

export default App;
