import React from "react";
import Element from "../Element/Element";
import { useSelector } from "react-redux";
const Template: React.FC<any> = ({ meta, id, builder, children , isTemplatePreview = false, templateId = null }) => {

  const loading = useSelector((state: { content: any }) => state.content.reload);

  return (
    <>
      <Element meta={meta} id={id} builder={builder} isTemplatePreview={isTemplatePreview} templateId={templateId}>
        {children}
      </Element>
    </>

  );
};

export default Template;
