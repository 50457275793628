// Tables.tsx
import React, { useEffect, useState } from 'react';
import { DndContext, DragEndEvent, DragOverlay } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import './tables.css';

import { Column } from './Column';
import { Item } from './Item';
import axios from 'axios';
import { API_URL } from '../../helpers/api';
import { useToast } from '../../components/Native/Toast/ToastContext';

interface Guest {
  idinvite: string;
  nombre: string;
  apellidos: string;
  adultoconf: string;
  ninoconf: string;
  count: number; // Suma de adultos y niños confirmados
}

interface ActiveInfo {
  id: string;
  column: string;
  index: number;
}

export function Tables() {
  const [items, setItems] = useState<{ [key: string]: string[] }>({});
  const [columnCount, setColumnCount] = useState(1);
  const [guestData, setGuestData] = useState<{ [key: string]: Guest }>({});
  const [activeInfo, setActiveInfo] = useState<ActiveInfo | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const [emptyData, setEmptyData] = useState(false);

  const addToast = useToast();

  // Estados para manejar la edición de nombres de mesa
  const [editingTable, setEditingTable] = useState<string | null>(null);
  const [editingTableName, setEditingTableName] = useState<string>('');

  // Función para sincronizar el plan de asientos
  const syncSeatingPlan = (
    savedPlan: { [key: string]: string[] },
    confirmedGuests: Guest[]
  ) => {
    const confirmedGuestIds = new Set(confirmedGuests.map(guest => guest.idinvite));

    // Inicializar nuevo plan de asientos
    const newSeatingPlan: { [key: string]: string[] } = {};

    // Recorrer cada mesa en el plan guardado
    Object.entries(savedPlan).forEach(([column, guestIds]) => {
      newSeatingPlan[column] = guestIds.filter(guestId => confirmedGuestIds.has(guestId));
    });

    // Identificar nuevos invitados confirmados no asignados
    const assignedGuestIds = new Set<string>();
    Object.values(newSeatingPlan).forEach(guestIds => {
      guestIds.forEach(id => assignedGuestIds.add(id));
    });

    const unassignedGuests = confirmedGuests
      .filter(guest => !assignedGuestIds.has(guest.idinvite))
      .map(guest => guest.idinvite);

    // Agregar los invitados no asignados a la columna "invitados"
    if (!newSeatingPlan['invitados']) {
      newSeatingPlan['invitados'] = [];
    }
    newSeatingPlan['invitados'].push(...unassignedGuests);

    return newSeatingPlan;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const idBoda = window.localStorage.getItem("idBoda");

        // Obtener invitados confirmados
        const resInvites = await axios.get(`${API_URL}getInvitationsConfirmed`, {
          params: { idBoda: idBoda }
        });


        if ( resInvites.data == '' ) {
          setEmptyData(true);
          return;
        }

        const confirmedGuests: Guest[] = resInvites.data.map((guest: any) => ({
          ...guest,
          count: Number(guest.adultoconf) + Number(guest.ninoconf),
        }));

        // Mapear datos de invitados
        const guestDataMap: { [key: string]: Guest } = {};
        confirmedGuests.forEach(guest => {
          guestDataMap[guest.idinvite] = guest;
        });
        setGuestData(guestDataMap);

        // Obtener plan de asientos guardado
        const resSeatingPlan = await axios.get(`${API_URL}getSeatingPlan`, {
          params: { idBoda: idBoda }
        });

        let savedSeatingPlan = resSeatingPlan.data.seatingPlan || {};

        // Sincronizar el plan de asientos
        const syncedSeatingPlan = syncSeatingPlan(savedSeatingPlan, confirmedGuests);

        // Asegurarse de que "invitados" exista
        if (!syncedSeatingPlan['invitados']) {
          syncedSeatingPlan['invitados'] = [];
        }

        // Actualizar el estado
        setItems(syncedSeatingPlan);

        // Actualizar el contador de columnas
        const mesaColumns = Object.keys(syncedSeatingPlan).filter(column => column.startsWith('Mesa '));
        setColumnCount(mesaColumns.length);

      } catch (err) {
        addToast('Error al cargar los datos', 'error');
        console.error(err);
      }
    };

    fetchData();
  }, []);

  // Función para agregar una nueva mesa y guardar los datos
  const addColumn = () => {
    const newColumnName = `Mesa ${columnCount + 1}`;
    
    setItems(prevItems => {
      const updatedItems = {
        ...prevItems,
        [newColumnName]: [],
      };
      
      // Guardar el plan de asientos actualizado
      saveSeatingPlan(updatedItems);
      
      return updatedItems;
    });
    
    setColumnCount(prevCount => prevCount + 1);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const saveSeatingPlan = async (seatingPlan: { [key: string]: string[] }) => {
    try {
      const idBoda = window.localStorage.getItem("idBoda");

      await axios.post(`${API_URL}saveSeatingPlan`, {
        idBoda,
        seatingPlan,
      });
    } catch (err) {
      addToast('Error al guardar el plan de asientos', 'error');
      console.error(err);
    }
  };

  const handleDragStart = (event: any) => {
    const id = event.active.id as string;
    const column = Object.keys(items).find(col => items[col].includes(id));
    if (column) {
      const index = items[column].indexOf(id);
      setActiveInfo({ id, column, index });
    }
  };

  const handleDragEnd = (event: DragEndEvent) => {
    setActiveInfo(null);

    const { active, over } = event;

    if (!over) {
      return;
    }

    const activeId = active.id as string;
    const sourceColumn = active.data.current?.column as string;
    const targetColumn = over.data.current?.column as string;

    if (typeof targetColumn !== 'string') {
      console.warn(`Invalid targetColumn: ${targetColumn}`);
      return;
    }

    if (!sourceColumn || !targetColumn) {
      return;
    }

    if (sourceColumn === targetColumn) {
      // Reordenar en la misma columna
      setItems(prevItems => {
        const columnItems = prevItems[sourceColumn];
        const oldIndex = columnItems.indexOf(activeId);
        const newIndex = columnItems.indexOf(over.id as string);

        if (oldIndex === -1 || newIndex === -1) {
          console.warn(`Indices inválidos: oldIndex=${oldIndex}, newIndex=${newIndex}`);
          return prevItems;
        }

        if (oldIndex !== newIndex) {
          const newColumnItems = arrayMove(columnItems, oldIndex, newIndex);

          const newItems = {
            ...prevItems,
            [sourceColumn]: newColumnItems,
          };

          // Guardar el plan de asientos actualizado
          saveSeatingPlan(newItems);

          return newItems;
        }
        return prevItems;
      });
    } else {
      // Mover a una columna diferente
      setItems(prevItems => {
        if (!Array.isArray(prevItems[targetColumn])) {
          console.warn(`La columna "${targetColumn}" no existe o no es un arreglo.`);
          return prevItems;
        }

        const sourceItems = [...prevItems[sourceColumn]];
        const targetItems = [...prevItems[targetColumn]];

        const oldIndex = sourceItems.indexOf(activeId);
        if (oldIndex === -1) {
          console.warn(`El invitado "${activeId}" no se encontró en la columna "${sourceColumn}".`);
          return prevItems;
        }
        sourceItems.splice(oldIndex, 1);

        // Insertar antes del elemento 'over.id' en la columna de destino
        const overIndex = targetItems.indexOf(over.id as string);
        if (overIndex !== -1) {
          targetItems.splice(overIndex, 0, activeId);
        } else {
          targetItems.push(activeId);
        }

        const newItems = {
          ...prevItems,
          [sourceColumn]: sourceItems,
          [targetColumn]: targetItems,
        };

        // Guardar el plan de asientos actualizado
        saveSeatingPlan(newItems);

        return newItems;
      });
    }
  };

  const handleDragCancel = () => {
    setActiveInfo(null);
  };

  // Función para eliminar una mesa y renombrar las mesas posteriores
  const resetTable = (tableName: string) => {
    if (!window.confirm(`¿Estás seguro de que deseas borrar ${tableName}?`)) {
      return;
    }

    setItems(prevItems => {
      const tableGuests = prevItems[tableName];
      const newItems = { ...prevItems };

      // Remover los invitados de la mesa
      delete newItems[tableName];

      // Agregar los invitados a la columna "invitados"
      newItems['invitados'] = [...(newItems['invitados'] || []), ...tableGuests];

      // Extraer el número de la mesa eliminada
      const tableNumber = parseInt(tableName.replace('Mesa ', ''), 10);

      // Encontrar todas las mesas con número mayor que la mesa eliminada
      const higherTables = Object.keys(newItems)
        .filter(col => col.startsWith('Mesa '))
        .map(col => ({
          name: col,
          number: parseInt(col.replace('Mesa ', ''), 10)
        }))
        .filter(table => table.number > tableNumber)
        .sort((a, b) => a.number - b.number);

      // Renombrar las mesas posteriores
      higherTables.forEach(table => {
        const newTableName = `Mesa ${table.number - 1}`;
        newItems[newTableName] = newItems[table.name];
        delete newItems[table.name];
      });

      // Actualizar el contador de columnas
      setColumnCount(prevCount => prevCount - 1);

      // Guardar el plan de asientos actualizado
      saveSeatingPlan(newItems);

      return newItems;
    });
  };

  const resetAllTables = () => {
    if (!window.confirm('¿Estás seguro de que deseas reiniciar todas las mesas?')) {
      return;
    }
    setItems(prevItems => {
      // Recopilar todos los invitados de las mesas
      const allGuests = Object.entries(prevItems).reduce((acc, [column, guestIds]) => {
        if (column !== 'invitados') {
          acc.push(...guestIds);
        }
        return acc;
      }, [] as string[]);

      // Crear un nuevo objeto con solo la columna "invitados"
      const newItems = {
        invitados: [...(prevItems['invitados'] || []), ...allGuests],
      };

      // Reiniciar el contador de columnas
      setColumnCount(0);

      // Guardar el plan de asientos actualizado
      saveSeatingPlan(newItems);

      return newItems;
    });
  };

  // Función para guardar el nuevo nombre de la mesa
  const handleTableNameSave = () => {
    const newName = editingTableName.trim();
    const oldName = editingTable;

    if (oldName && newName && newName !== oldName) {
      if (items[newName]) {
        alert('Ya existe una mesa con ese nombre.');
      } else {
        setItems(prevItems => {
          const updatedItems = { ...prevItems };
          updatedItems[newName] = updatedItems[oldName];
          delete updatedItems[oldName];

          // Guardar el plan de asientos actualizado
          saveSeatingPlan(updatedItems);

          return updatedItems;
        });
      }
    }
    // Restablecer el estado de edición
    setEditingTable(null);
    setEditingTableName('');
  };

  return (
    <>

      <DndContext
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
      >
        <div className="buttons-container">
          <button className='button-tables' onClick={addColumn}>Agregar Mesa</button>
          <button onClick={resetAllTables} className="reset-all-button">Reiniciar Mesas</button>
        </div>
        <div className="root">
          {emptyData ? <p>No hay invitaciones creadas</p> : null }
          {Object.entries(items).map(([column, itemIds]) => {
            // Filtrar los invitados si es la columna "invitados"
            const filteredItemIds = column === 'invitados'
              ? itemIds.filter(id => {
                  const guest = guestData[id];
                  const fullName = `${guest.nombre} ${guest.apellidos}`.toLowerCase();
                  return fullName.includes(searchQuery.toLowerCase());
                })
              : itemIds;

            // Calcular el total de invitados para las mesas
            let totalGuests = 0;
            if (column.startsWith('Mesa ')) {
              totalGuests = itemIds.reduce((sum, id) => sum + (guestData[id]?.count || 1), 0);
            }

            return (
              <Column
                key={column}
                id={column}
                className={column === 'invitados' ? 'invitados-column' : ''}
              >
                {/* Componente para editar o mostrar el nombre de la mesa */}
                {column === editingTable ? (
                  // Renderizar campo de entrada para editar el nombre
                  <div className="column-title">
                    <input
                      type="text"
                      value={editingTableName}
                      onChange={(e) => setEditingTableName(e.target.value)}
                      onBlur={handleTableNameSave}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleTableNameSave();
                        }
                      }}
                      autoFocus
                    />
                  </div>
                ) : (
                  // Mostrar el nombre de la mesa y botón para editar
                  <div className="column-title">
                    <h3>{column}</h3>
                    {column.startsWith('Mesa ') && (
                      <button onClick={() => {
                        setEditingTable(column);
                        setEditingTableName(column);
                      }} className="edit-table-button">Editar Nombre</button>
                    )}
                  </div>
                )}

                {/* Mostrar el total de invitados si es una mesa */}
                {column.startsWith('Mesa ') && (
                  <h3 className="column-title">
                    ({totalGuests} invitados)
                  </h3>
                )}

                {/* Campo de búsqueda si es la columna de invitados */}
                {column === 'invitados' && (
                  <input
                    type="text"
                    placeholder="Buscar invitados..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="search-input"
                  />
                )}

                {/* Botón para borrar la mesa */}
                {column.startsWith('Mesa ') && (
                  <button onClick={() => resetTable(column)} className="reset-table-button">
                    Borrar Mesa
                  </button>
                )}

                <SortableContext items={filteredItemIds} strategy={verticalListSortingStrategy}>
                  {filteredItemIds.map((id, index) => (
                    <Item key={id} id={id} index={index} column={column} guestData={guestData} />
                  ))}
                </SortableContext>
              </Column>
            );
          })}
        </div>
        <DragOverlay>
          {activeInfo ? (
            <Item
              id={activeInfo.id}
              column={activeInfo.column}
              index={activeInfo.index}
              guestData={guestData}
              isOverlay={true}
            />
          ) : null}
        </DragOverlay>
      </DndContext>
    </>
  );
}
