import { useDispatch } from 'react-redux';
import { AppDispatch, fetchContent, ReplaceTemplate } from '../../store/actions/dataSlice';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../helpers/api';
import { useToast } from '../../components/Native/Toast/ToastContext';
import TemplatesWeddings from './Templates-weddings';
import TemplatesEvents from './Templates-events';

const Templates = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const addToast = useToast();
    let userType = Number(localStorage.getItem('userType') )
    console.log(userType)

    const handleClickTemplate = (templateId: any) => {
      if (window.confirm(`¿Estás seguro/a de reemplazar el diseño? Todas las imágenes y textos modificados se perderán.`)) {

        const idBoda = localStorage.getItem('idBoda')

        const obj = JSON.stringify({
          "idBoda": idBoda,
          "idTemplate":templateId,
          "url": "www.bobadilla.com",
          "userType": userType
      });
        axios.post(`${API_URL}updateTemplate`, obj, {
          headers: {
            "Content-Type": "application/json",
          },
        })
      .then(() => {
        dispatch(fetchContent())
        addToast('Diseño cambiado', 'success');
        //navigate('/builder');
      })
      .catch(() => {
        addToast('Hubo un error', 'error');
      });
          //dispatch(ReplaceTemplate({ template: templateObj }));W
      }
    };

    const handlePreviewTemplate = (templateId: any) => {
      window.open('/TemplateRenderer?idTemplate=' + templateId, '_blank');
    }

    return (
        <>
        <div className='max-container'>
            <div className="header-templates">
                <div className="header-templates-box">
                    <h1><i className="fa-solid fa-wand-magic-sparkles"></i> Galería de diseños</h1>
                </div>

            </div>
            <div className="templates-container">
                <div className="templates-box">
                    {userType === 0 ? (
                    <TemplatesWeddings             
                        onClickTemplate={handleClickTemplate} 
                        onPreviewTemplate={handlePreviewTemplate}  
                    />
                    ) : (
                        <TemplatesEvents            
                            onClickTemplate={handleClickTemplate} 
                            onPreviewTemplate={handlePreviewTemplate}  
                        />
                    )}
                </div>
            </div>
        </div>   

        </>
    );
};

export default Templates;
