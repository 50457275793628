import { createContext, useContext, useState } from 'react';
import Toast from './Toast';

type ToastType = 'success' | 'error' | 'warning';
type ToastContextType = (message: string, type: ToastType) => void;

const ToastContext = createContext<ToastContextType | undefined>(undefined);



export const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error('useToast debe ser utilizado dentro de un ToastProvider');
  }
  return context;
};

export const ToastProvider = ({ children }: any) => {
  const [toasts, setToasts] = useState<any>([]);

  const addToast = (message: any, type: any) => {
    const id = Date.now();
    setToasts([...toasts, { id, message, type }]);

    setTimeout(() => {
      setToasts((toasts: any) => toasts.filter((toast: any) => toast.id !== id));
    }, 3000);
  };

  return (
    <ToastContext.Provider value={addToast}>
      {children}
      <div className="toast-container">
        {toasts.map((toast: any) => (
          <Toast key={toast.id} message={toast.message} type={toast.type} />
        ))}
      </div>
    </ToastContext.Provider>
  );
};
