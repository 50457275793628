import LoadingIndicator from "../../components/Native/Loading/LoadingIndicator";
import BuilderRender from "../../components/Renders/BuilderRender/BuilderRender";
import ModalsRender from "../../components/Renders/ModalsRender/ModalsRender";

const Builder = () => {
    return ( 
      <>
          <ModalsRender />
          <BuilderRender />
          <LoadingIndicator />
      </>

     );
}
 
export default Builder;