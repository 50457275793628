import { useEffect, useState } from 'react';
import axios from 'axios';
import './planner.css';
import { API_URL } from '../../helpers/api';
import { useToast } from '../../components/Native/Toast/ToastContext';

interface ChecklistItem {
    checked: number;
    idboda: string;
    iditem: string;
    itemdate: string;
    itemname: string;
    itemtype: string;
}

const Planner = () => {
    const addToast = useToast();

    const [data, setData] = useState<ChecklistItem[]>([]);

    const [priority, setPriority] = useState('low');
    const [name, setName] = useState('');
    const [date, setDate] = useState('');

    const [showModal, setShowModal] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [currentItem, setCurrentItem] = useState<ChecklistItem | null>(null);

    const [filterPriority, setFilterPriority] = useState('all'); // Nuevo estado para el filtro

    const getData = async () => {
        try {
            const localStorage = window.localStorage;
            const idBoda = localStorage.getItem("idBoda") || '';
            const res = await axios.get(`${API_URL}getChecklist`, {
                params: { idBoda: idBoda }
            });
            if (res.status === 204) {
                console.log('No hay ítems');
            } else {
                const sortedData = res.data.sort((a: ChecklistItem, b: ChecklistItem) => a.checked - b.checked);
                setData(sortedData);
            }
        } catch (err: any) {
            if (err.response) {
                addToast('Error al obtener el checklist', 'error');
            }
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setPriority(event.target.value);
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDate(event.target.value);
    };

    const handleCheckboxChange = async (itemId: string, currentChecked: boolean) => {
        try {
            const res = await axios.post(`${API_URL}updateChecklistItem`, {
                idItem: itemId,
                checked: currentChecked ? 0 : 1
            });
            if (res.status === 200) {
                addToast('Ítem actualizado exitosamente', 'success');
                const newCheckedValue = currentChecked ? 0 : 1;
                setData(prevData => {
                    const updatedData = prevData.map(item =>
                        item.iditem === itemId ? { ...item, checked: newCheckedValue } : item
                    );
                    // Ordena los ítems después de actualizar
                    return updatedData.sort((a, b) => a.checked - b.checked);
                });
            } else {
                addToast('Error al actualizar el ítem', 'error');
            }
        } catch (err) {
            addToast('Error al actualizar el ítem', 'error');
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const localStorage = window.localStorage;
        const idBoda = localStorage.getItem("idBoda");

        if (!idBoda || !name.trim() || !date.trim()) {
            addToast('Por favor, completa todos los campos antes de enviar.', 'error');
            return;
        }

        try {
            const newItem = {
                idboda: idBoda,
                itemname: name,
                itemdate: date,
                itemtype: priority,
                checked: 0
            };

            if (isEditMode && currentItem) {
                // Modo edición
                const res = await axios.post(`${API_URL}updateChecklistItemDetails`, {
                    ...newItem,
                    idItem: currentItem.iditem
                });

                if (res.status === 200) {
                    addToast('Ítem actualizado exitosamente', 'success');
                    // Actualizar el ítem en el estado local
                    setData(prevData =>
                        prevData.map(item =>
                            item.iditem === currentItem.iditem ? { ...item, ...newItem } : item
                        )
                    );
                } else {
                    addToast('Error al actualizar el ítem', 'error');
                }
            } else {
                // Modo agregar
                const res = await axios.post(`${API_URL}addChecklistItem`, newItem);

                if (res.status === 201) {
                    addToast('Ítem agregado exitosamente', 'success');
                    getData();
                } else {
                    addToast('Error al agregar el ítem', 'error');
                }
            }

            // Resetear el estado del formulario y cerrar el modal
            setName('');
            setDate('');
            setPriority('low');
            setCurrentItem(null);
            setIsEditMode(false);
            setShowModal(false);
        } catch (err) {
            addToast('Error al procesar el formulario', 'error');
            console.error(err);
        }
    };

    const handleDeleteItem = async (itemId: string) => {
        const confirmDelete = window.confirm("¿Estás seguro de que deseas eliminar este ítem?");
        if (!confirmDelete) {
            return;
        }

        try {
            const localStorage = window.localStorage;
            const idBoda = localStorage.getItem("idBoda");

            if (!idBoda) {
                addToast('ID de boda no encontrado.', 'error');
                return;
            }
            const res = await axios.post(`${API_URL}deleteChecklistItem`, {
                idItem: itemId,
                idboda: idBoda
            });

            if (res.status === 200) {
                setData(prevData => prevData.filter(item => item.iditem !== itemId));
                addToast('Ítem eliminado exitosamente', 'success');
            } else {
                addToast('Error al eliminar el ítem', 'error');
            }
        } catch (err) {
            addToast('Error al eliminar el ítem', 'error');
            console.error(err);
        }
    };

    const openModal = () => {
        setShowModal(true);
        setIsEditMode(false);
        setCurrentItem(null);
    };

    const closeModal = () => {
        setShowModal(false);
        setName('');
        setDate('');
        setPriority('low');
        setCurrentItem(null);
        setIsEditMode(false);
    };

    const handleEditItem = (item: ChecklistItem) => {
        setCurrentItem(item);
        setName(item.itemname);
        setDate(item.itemdate);
        setPriority(item.itemtype);
        setIsEditMode(true);
        setShowModal(true);
    };

    const getPriorityClass = (itemtype: string) => {
        switch(itemtype) {
            case 'urgent':
                return 'planner-urgent';
            case 'medium':
                return 'planner-medium';
            case 'low':
                return 'planner-low';
            default:
                return '';
        }
    };

    return (
        <div className="planner-container">
            {/* Modal */}
            {showModal && (
                <div className={`modal-planner ${!showModal ? 'closing' : ''}`}>
                    <div className={`modal-content ${!showModal ? 'closing' : ''}`}>
                        <span className="close-button" onClick={closeModal}>&times;</span>
                        <h2>{isEditMode ? 'Editar Ítem' : 'Agregar Ítem'}</h2>
                        <form onSubmit={handleSubmit}>
                            <input
                                type="text"
                                placeholder='Nombre del ítem'
                                value={name}
                                onChange={handleNameChange}
                                required
                            />
                            <input
                                type="date"
                                placeholder='Fecha del ítem'
                                value={date}
                                onChange={handleDateChange}
                                required
                            />
                            <label>
                                Prioridad:
                                <select value={priority} onChange={handleSelectChange}>
                                    <option value="urgent">Urgente</option>
                                    <option value="medium">Medio</option>
                                    <option value="low">Bajo</option>
                                </select>
                            </label>
                            <button type="submit">{isEditMode ? 'Guardar Cambios' : 'Agregar'}</button>
                        </form>
                    </div>
                </div>
            )}

            <div className="planner-navbar">
                <h2 className='planner-navbar-title'>Checklist Planner</h2>
                <button className='planner-navbar-button' onClick={openModal}>Agregar ítem</button>
            </div>
            <div className="planner-filters">
                    <label>
                        Filtrar por prioridad:
                        <select value={filterPriority} onChange={(e) => setFilterPriority(e.target.value)}>
                            <option value="all">Todos</option>
                            <option value="urgent">Urgente</option>
                            <option value="medium">Medio</option>
                            <option value="low">Bajo</option>
                        </select>
                    </label>
                </div>
            <div className="planner-table">
                <div className="planner-table-nav"></div>
                <div className="planner-table-body">
                    {data.length > 0 ? (
                        data
                        .filter(item => filterPriority === 'all' || item.itemtype === filterPriority)
                        .map((item) => (
                            <div key={item.iditem} className="planner-item">
                                <div className="planner-item-info">
                                    <label className={item.checked === 1 ? 'strikethrough-item' : ''}>
                                        <input 
                                            type="checkbox"
                                            className='planner-item-checkbox' 
                                            checked={item.checked === 1} 
                                            onChange={() => handleCheckboxChange(item.iditem, item.checked === 1)}
                                        />
                                        {item.itemname}
                                    </label>
                                </div>
                                <div className="planner-item-desc">
                                    <p>{new Date(item.itemdate + 'T00:00:00').toLocaleDateString()}</p>
                                    <div className={`planner-item-type ${getPriorityClass(item.itemtype)}`}></div>
                                    <i className=" planner-button fa-solid fa-pencil" onClick={() => handleEditItem(item)}></i>
                                    <i className=" planner-button fa-solid fa-trash-can" onClick={() => handleDeleteItem(item.iditem)}></i>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>No hay ítems en el checklist.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Planner;
