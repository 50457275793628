// TextModal.tsx
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ModalProps } from "../../../store/actions/dataSlice";
import Modal from "../../Native/Modal/Modal";
import ColorPicker from "../../Native/ColorPicker/ColorPicker";
import './textmodal.css';

const TextModal: React.FC<ModalProps> = ({ element }) => {
  const selectedElement = useSelector((state: { content: any }) => state.content.selectedElement);

  const [data, setData] = useState<string | undefined>(selectedElement.data);
  const [color, setColor] = useState<string | undefined>(selectedElement.color);
  
  // Convertimos 'hide' de string a booleano al inicializar el estado
  const [hide, setChecked] = React.useState<boolean>(selectedElement.hide === "true");

  // Crea una referencia para el input
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setData(selectedElement.data);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [selectedElement.data]);

  const handleText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData(e.target.value);
  };

  const handleColor = (e: React.ChangeEvent<HTMLInputElement>) => {
    setColor(e.target.value);
  };

  const handleChange = () => {
    // Actualizamos el estado 'hide' invirtiendo su valor actual
    setChecked((prevHide) => !prevHide);
  };

  return (
    <Modal data={data} element={element} color={color} hideElement={hide}>
      <input type="text" ref={inputRef} value={data} onChange={handleText} />
      <ColorPicker value={color} onChange={handleColor} />
      <div className="checkbox-container">
        <input
          type="checkbox"
          className="checkbox-input"
          checked={hide}
          onChange={handleChange}
        />
        <label className="checkbox-label">Ocultar elemento</label>
      </div>
    </Modal>
  );
};

export default TextModal;
