import './navbarBuilder.css'
import { useLocation, useNavigate } from 'react-router-dom';
import { handleSave } from '../../../helpers/helpers';
import { useSelector } from 'react-redux';
import { useToast } from '../../Native/Toast/ToastContext';

// font awesome icons import

const NavbarBuilder = () => {
  const navigate = useNavigate();
  let state = useSelector((state: { content: any }) => state.content.contents);

  let weddingURL = useSelector((state: { content: any }) => state.content.contents.url);

  const location = useLocation();
  const addToast = useToast();
  
  // Verifica si el usuario está en la ruta "/builder"
  const isBuilderPage = location.pathname === '/builder';

    return (
        <div className="navbar-builder">
          <div className="left">
            <a href=''></a>
          </div>
          {isBuilderPage ? (
              <div className="mode-indicator">
                <span className="mode-text"><i className="fa-regular fa-pen-to-square"></i> Modo Edición</span>
              </div>
            ) : (
              <div className="mode-indicator preview">
                <span className="mode-text"><i className="fa-sharp fa-light fa-eye"></i>Modo Vista Previa</span>
              </div>
            )
          }

          <div className="right">

          {isBuilderPage ? (
              <a className='preview-button' onClick={() => navigate('/viewer') }>Vista previa</a>
            ) : (
              <a className='publish-button' onClick={() => navigate('/builder') }>Editar </a>
            )
          }
          {
            /* <a className='publish-button' onClick={(e) => handleSave(state, addToast)} >Publicar</a> */
          }

          </div>
        </div>
      );
}
 
export default NavbarBuilder;

